/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  /* jshint ignore:start */

  function _toConsumableArray(arr) {
    return (
      _arrayWithoutHoles(arr) ||
      _iterableToArray(arr) ||
      _unsupportedIterableToArray(arr) ||
      _nonIterableSpread()
    );
  }

  function _nonIterableSpread() {
    throw new TypeError(
      "Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."
    );
  }

  function _unsupportedIterableToArray(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(o);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
      return _arrayLikeToArray(o, minLen);
  }

  function _iterableToArray(iter) {
    if (
      (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null) ||
      iter["@@iterator"] != null
    )
      return Array.from(iter);
  }

  function _arrayWithoutHoles(arr) {
    if (Array.isArray(arr)) return _arrayLikeToArray(arr);
  }

  function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for (var i = 0, arr2 = new Array(len); i < len; i++) {
      arr2[i] = arr[i];
    }
    return arr2;
  }

  /**
   * Pepper.js
   * inserts item details in between rows responsively
   */
  var Pepper = function Pepper(opt) {
    var STATE = {
      INITIAL: "INITIAL",
      PENDING: "PENDING",
      SUCCESS: "SUCCESS",
    };

    var self =
      (opt === null || opt === void 0 ? void 0 : opt.id) ||
      document.getElementById("pepper");
    var items =
      (opt === null || opt === void 0 ? void 0 : opt.items) ||
      self.querySelectorAll(".pepper-item");
    var indexCurr = null;
    var state = STATE.INITIAL;

    /**
     * Returns specific HTML element
     * so to insert div before breaking in new row
     */

    var getFirstItemInNextRow = function getFirstItemInNextRow(el) {
      if (el.nextElementSibling === null) {
        return el.nextElementSibling; // last item case
      }

      if (
        el.getBoundingClientRect().top ===
        el.nextElementSibling.getBoundingClientRect().top
      ) {
        return getFirstItemInNextRow(el.nextElementSibling); // find next item case
      }

      return el.nextElementSibling; // reached last item case
    };

    var detailRemove = function detailRemove() {
      var _document$getElementB;

      (_document$getElementB = document.getElementById("pepper-detail")) ===
        null || _document$getElementB === void 0
        ? void 0
        : _document$getElementB.remove();
    };

    var detailSet = function detailSet(target) {
      state = STATE.PENDING;

      var st = $(document).scrollTop();
      var el = _toConsumableArray(items).find(function (item, index) {
        return index === indexCurr;
      });
      target.setAttribute("data-state", "PENDING");

      console.log("start fetching..");

      axios
        .get(items[indexCurr].getAttribute("data-url"))
        .then(function (response) {
          console.log("fetched");

          detailRemove(); // !!! remove first

          var htmlEl = document.createElement("div");
          htmlEl.setAttribute("id", "pepper-detail");
          htmlEl.classList = "pepper-detail";

          var content =
            '\
            <div class="pepper-detail-content" style="background-color: ' +
            items[indexCurr].getAttribute("data-bg-color") +
            "; color: " +
            items[indexCurr].getAttribute("data-text-color") +
            ';">' +
            response.data.content.rendered +
            '\
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" style="enable-background:new 0 0 30 30" xml:space="preserve" width="30" class="cancel">\
              <switch>\
                <g>\
                  <path d="m18.5 15 5.3-5.3c1-1 1-2.6 0-3.5-1-1-2.6-1-3.5 0L15 11.5 9.7 6.2c-1-1-2.6-1-3.5 0-1 1-1 2.6 0 3.5l5.3 5.3-5.3 5.3c-1 1-1 2.6 0 3.5 1 1 2.6 1 3.5 0l5.3-5.3 5.3 5.3c1 1 2.6 1 3.5 0 1-1 1-2.6 0-3.5L18.5 15z"/>\
                </g>\
              </switch>\
            </svg>\
            </div>\
          ';

          console.log(window.location + "dist");

          $(htmlEl).html(content);

          self.insertBefore(htmlEl, getFirstItemInNextRow(el));
          $("#pepper-detail .cancel").on("click", detailRemove);
          $(document).scrollTop(st);

          target.removeAttribute("data-state");

          state = STATE.SUCCESS;
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    var update = function update() {
      if (indexCurr !== null) {
        var saved = document.getElementById("pepper-detail");
        var el = _toConsumableArray(items).find(function (item, index) {
          return index === indexCurr;
        });
        detailRemove();
        self.insertBefore(saved, getFirstItemInNextRow(el));
      }
    };

    var onClick = function onClick(event) {
      if (state === STATE.PENDING) {
        return false;
      }

      if (
        _toConsumableArray(items).indexOf(event.currentTarget) === indexCurr
      ) {
        indexCurr = null;
        return false;
      }

      indexCurr = _toConsumableArray(items).indexOf(event.currentTarget);
      detailSet(event.currentTarget);
    };

    var init = function init() {
      items.forEach(function (items) {
        return items.addEventListener("click", onClick);
      });

      // bootstrap 4
      var xs = window.matchMedia("(min-width: 0px) and (max-width: 575px)");
      var sm = window.matchMedia("(min-width: 576px) and (max-width: 767px)");
      var md = window.matchMedia("(min-width: 768px) and (max-width: 991px)");
      var lg = window.matchMedia("(min-width: 992px) and (max-width: 1199px)");
      var xl = window.matchMedia("(min-width: 1200px) and (max-width: 1439px)");
      var xxl = window.matchMedia("(min-width: 1440px)");
      xs.addEventListener("change", function (e) {
        if (e.matches) {
          update();
        }
      });
      sm.addEventListener("change", function (e) {
        if (e.matches) {
          update();
        }
      });
      md.addEventListener("change", function (e) {
        if (e.matches) {
          update();
        }
      });
      lg.addEventListener("change", function (e) {
        if (e.matches) {
          update();
        }
      });
      xl.addEventListener("change", function (e) {
        if (e.matches) {
          update();
        }
      });
      xxl.addEventListener("change", function (e) {
        if (e.matches) {
          update();
        }
      });
    };

    return {
      init: init,
    };
  };
  /* jshint ignore:end */

  function getViewport() {
    var viewPortWidth;
    var viewPortHeight;

    // the more standards compliant browsers (mozilla/netscape/opera/IE7) use window.innerWidth and window.innerHeight
    if (typeof window.innerWidth !== "undefined") {
      viewPortWidth = window.innerWidth;
      viewPortHeight = window.innerHeight;
    }

    // IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)
    else if (
      typeof document.documentElement !== "undefined" &&
      typeof document.documentElement.clientWidth !== "undefined" &&
      document.documentElement.clientWidth !== 0
    ) {
      viewPortWidth = document.documentElement.clientWidth;
      viewPortHeight = document.documentElement.clientHeight;
    }

    // older versions of IE
    else {
      viewPortWidth = document.getElementsByTagName("body")[0].clientWidth;
      viewPortHeight = document.getElementsByTagName("body")[0].clientHeight;
    }
    return { w: viewPortWidth, h: viewPortHeight };
  }

  function rafScroll(cb) {
    // Avoid calculations if not needed
    var lastPosition = -1;
    var render = function () {
      if (lastPosition === window.pageYOffset) {
        return false;
      } else {
        lastPosition = window.pageYOffset;
      }
      cb();
    };
    var draw = function () {
      requestAnimationFrame(draw);
      render();
    };

    // https://gist.github.com/paulirish/1579671
    var lastTime = 0;
    var vendors = ["ms", "moz", "webkit", "o"];

    for (var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
      window.requestAnimationFrame =
        window[vendors[x] + "RequestAnimationFrame"];
      window.cancelAnimationFrame =
        window[vendors[x] + "CancelAnimationFrame"] ||
        window[vendors[x] + "CancelRequestAnimationFrame"];
    }

    if (!window.requestAnimationFrame) {
      window.requestAnimationFrame = function (callback, element) {
        var currTime = new Date().getTime();
        var timeToCall = Math.max(0, 16 - (currTime - lastTime));
        var id = window.setTimeout(function () {
          callback(currTime + timeToCall);
        }, timeToCall);
        lastTime = currTime + timeToCall;
        return id;
      };
    }

    if (!window.cancelAnimationFrame) {
      window.cancelAnimationFrame = function (id) {
        clearTimeout(id);
      };
    }

    draw();
  }

  function onPlayerReady(event) {
    // event.target.playVideo();
  }
  function onPlayerStateChange(event) {}
  function onYouTubeIframeAPIReady() {
    $(".yt-player").each(function () {
      var el = $(this);
      var ytPlayer = new YT.Player(el.get(0), {
        height: "360",
        width: "640",
        videoId: el.attr("data-id"),
        playerVars: {
          modestbranding: 0,
          rel: 0,
          showinfo: 0,
        },
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange,
        },
      });
    });
  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    common: {
      init: function () {
        var backToTop = function () {
          TweenMax.to(window, 1.3, {
            scrollTo: { y: 0 },
            ease: Expo.easeInOut,
          });
        };

        if ($(".yt-player").length > 0) {
          var tag = document.createElement("script");
          tag.src = "https://www.youtube.com/iframe_api";
          var firstScriptTag = document.getElementsByTagName("script")[0];
          firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

          $(window).load(function () {
            onYouTubeIframeAPIReady();
          });
        }

        if ($(".subnav").length > 0) {
          $(".subnav").stickybits({
            useStickyClasses: true,
            stickyBitStickyOffset: 0,
          });

          $(".subnav-link").on("click", function (event) {
            event.preventDefault();
            var el = $(this);
            var scrollToId = el.attr("data-id");
            var toEl = $("section#" + scrollToId);
            var top = toEl.offset().top - $(".subnav").outerHeight();
            // var top = toEl.offset().top;
            var mostBottomTop = $(document).height() - $(window).height();
            if (top > mostBottomTop) {
              TweenMax.to(window, 1.3, {
                scrollTo: { y: mostBottomTop },
                ease: Expo.easeInOut,
                onComplete: function () {
                  $(".subnav-item").removeClass("is-active");
                  el.closest(".subnav-item").addClass("is-active");
                },
              });
            } else {
              TweenMax.to(window, 1.3, {
                scrollTo: { y: top },
                ease: Expo.easeInOut,
                onComplete: function () {
                  $(".subnav-item").removeClass("is-active");
                  el.closest(".subnav-item").addClass("is-active");
                },
              });
            }
          });
        }

        if ($(".slick-carousel").length > 0) {
          $.getScript(
            "//cdn.jsdelivr.net/gh/kenwheeler/slick@1.8.1/slick/slick.min.js"
          )
            .done(function (script, textStatus) {
              // console.log($('.slick-carousel > div').length > 1);
              $(".slick-carousel").each(function () {
                var el = $(this);
                el.on("init", function (event, slick, direction) {
                  el.addClass("is-loaded");
                });
                el.slick({
                  adaptiveHeight: true,
                  lazyLoad: "progressive",
                  dots: $(".slick-carousel > div").length > 1 ? true : false,
                  nextArrow:
                    '<i class="fa fa-angle-right" aria-hidden="true"></i>',
                  prevArrow:
                    '<i class="fa fa-angle-left" aria-hidden="true"></i>',
                  appendDots: el.closest(".slick-wrap").find(".slick-bullet"),
                  customPaging: function (slider, i) {
                    var title = $(slider.$slides).eq(i).attr("data-title");
                    var bulletColor = $(slider.$slides)
                      .eq(i)
                      .attr("data-bullet-color");
                    el.find(".slick-arrow").css("color", bulletColor);
                    return (
                      '<p style="color:' + bulletColor + ';">' + title + "</p>"
                    );
                  },
                  responsive: [
                    {
                      breakpoint: 576, // 768
                      settings: {
                        customPaging: function (slider, i) {
                          return $('<button type="button" />').text(i + 1);
                        },
                      },
                    },
                  ],
                });
              });
            })
            .fail(function (jqxhr, settings, exception) {});
        }

        if ($(".sm").length > 0) {
          $.getScript(
            "//cdnjs.cloudflare.com/ajax/libs/scrollmonitor/1.1.3/scrollMonitor.min.js"
          )
            .done(function (script, textStatus) {
              $(".sm").each(function () {
                var el = $(this);
                var elementWatcher = scrollMonitor.create(el);
                elementWatcher.fullyEnterViewport(function () {
                  var el = $(this.watchItem);
                  var id = el.attr("id");
                  var nav = $('.subnav-link[data-id="' + id + '"]');
                  var item = nav.closest(".subnav-item");
                  $(".subnav-item").removeClass("is-active");
                  item.addClass("is-active");
                });
              });
            })
            .fail(function (jqxhr, settings, exception) {});
        }

        rafScroll(function () {
          var st = $(window).scrollTop();
          if (st < 80) {
            if ($(".backtotop").hasClass("is-active")) {
              $(".backtotop").removeClass("is-active");
            }
          } else if (st + $(window).height() > $(document).height() - 80) {
            if ($(".backtotop").hasClass("is-active")) {
              $(".backtotop").removeClass("is-active");
            }
          } else {
            if (!$(".backtotop").hasClass("is-active")) {
              $(".backtotop").addClass("is-active");
            }
          }
        });
        $(".backtotop").on("click", backToTop);
      },
      finalize: function () {},
    },
    home: {
      init: function () {},
      finalize: function () {},
    },
    about_us: {
      init: function () {},
      finalize: function () {},
    },
    a_z_world: {
      init: function () {
        const pepper = new Pepper();
        pepper.init();
      },
      finalize: function () {},
    },
    single_event: {
      init: function () {},
      finalize: function () {},
    },
    single_art_zoo_family: {
      init: function () {
        // /*jshint multistr:true */
        // var router = new VueRouter({
        //   mode: 'history',
        //   routes: [
        //     { path: '/art-zoo-family/:letter' }
        //   ]
        // });
        // var app = new Vue({
        //   el: '#app',
        //   router: VueRouter,
        //   data: {
        //     message: 'test'
        //   },
        //   template: '\
        //   <div id="app">\
        //     <h2>{{ message }}</h2>\
        //     <router-view></router-view>\
        //   </div>\
        //   '
        // });
        // /*jshint multistr:true */
      },
      finalize: function () {},
    },
    contact: {
      init: function () {},
      finalize: function () {},
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, "finalize");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
